import { Icon } from './Icon'
import { MiniCard } from './MiniCard'
import { IconStar } from '/icons'

import styles from './StarRating.css'

export function StarRating({ onChange, value, length = 5 }) {
  const stars = Array.from({ length }).map((_, x) => x + 1)

  return (
    <MiniCard>
      <div className={styles.component}>
        {stars.map(starValue => (
          <Star
            key={starValue}
            active={starValue <= value}
            onChange={() => handleChange(starValue)}
          />
        ))}
      </div>
    </MiniCard>
  )

  function handleChange(starValue) {
    onChange(value === 1 ? null : starValue)
  }
}

function Star({ active, onChange }) {
  return (
    <button type='button' onClick={onChange} className={cx(styles.componentStar, active && styles.active)}>
      <Icon icon={IconStar} />
    </button>
  )
}
