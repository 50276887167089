import { StarRating } from '/features/buildingBlocks/StarRating'
import { Button } from '/features/buildingBlocks/Button'
import { Modal } from '/features/buildingBlocks/Modal'
import { IconArrowRight, IconCross } from '/icons'
import { Icon } from '/features/buildingBlocks/Icon'
import { CheckboxWithLabel } from '/features/buildingBlocks/Checkbox'
import { Loader } from '/features/buildingBlocks/Loader'
import { Heading32, Heading24 } from '/features/buildingBlocks/Heading'

import { useUser } from '/machinery/FirebaseAuthProvider'
import { useFirebaseDatabase, useFirebaseValue } from '/machinery/useFirebaseDatabase'
import { query, ref, set } from 'firebase/database'
import { useReportError } from '/machinery/ReportError'
import { useClientConfig } from '/machinery/ClientConfig'
import { routeMap } from '/routeMap'

import styles from './StatementsDetailsForm.css'

// TODO refactor to work with kaliber/forms? Let see if it makes things easyer when we the other form working.
export function StatementsDetailsForm({ statement }) {
  const { answerOptions } = useClientConfig()
  const { form, setValueOnKey, isFetching } = useFormState({ id: statement.id })

  if (isFetching) return <Loader />

  const meaningTitle = statement.formFields.question
  const meaningOptions = statement.formFields.options

  const importanceWhyTitle = 'Waarom is dit belangrijk voor u?'
  const whyDiscussTitle = 'Waarom wilt u dit met uw zorgverlener bespreken?'

  return (
    <form onSubmit={handleSubmit} className={styles.component}>
      <FieldGroup title={meaningTitle} >
        <CheckboxListWithOverlay
          onChange={(value) => setValueOnKey({ value, key: 'meaning' })}
          value={form['meaning']}
          options={meaningOptions}
          title={meaningTitle}
        />
      </FieldGroup>

      {statement.showFrequency && (
        <FieldGroup title={'U doet dit...'} >
          <CheckboxList
            value={form['frequency']}
            options={answerOptions.frequencyOptions}
            onChange={(value) => setValueOnKey({ value, key: 'frequency' })}
          />
        </FieldGroup>
      )}

      <FieldGroup title={'Hoe belangrijk is dit onderwerp in uw leven?'} >
        <StarRating
          value={form['importance']}
          onChange={(value) => setValueOnKey({ value, key: 'importance' })}
        />
      </FieldGroup>

      <FieldGroup title={importanceWhyTitle} >
        <CheckboxListWithOverlay
          value={form['importanceWhy']}
          onChange={(value) => setValueOnKey({ value, key: 'importanceWhy' })}
          options={answerOptions.importanceWhyOptions}
          title={importanceWhyTitle}
        />
      </FieldGroup>

      <FieldGroup title={'Hoe graag wilt u dit onderwerp met uw zorgverlener bespreken?'} >
        <StarRating
          value={form['subjectToDiscussRating']}
          onChange={(value) => setValueOnKey({ value, key: 'subjectToDiscussRating' })}
        />
      </FieldGroup>

      <FieldGroup title={whyDiscussTitle} >
        <CheckboxListWithOverlay
          value={form['whyDiscuss']}
          onChange={(value) => setValueOnKey({ value, key: 'whyDiscuss' })}
          options={answerOptions.subjectToDiscussRatingOptions}
          title={whyDiscussTitle}
        />
      </FieldGroup>

      <Button type='submit' layoutClassName={styles.buttonLayout}>
        Naar het overzicht
      </Button>
    </form>
  )

  function handleSubmit(e) {
    e.preventDefault()
    window.location.href = routeMap.app.stepTwo.overview({ language: 'nl' })
  }
}

function FieldGroup({ title, children }) {
  return (
    <div className={styles.componentFieldGroup}>
      <Heading24 h={3} layoutClassName={styles.fieldGroupHeadingLayout} {...{ title }} />
      {children}
    </div>
  )
}

function CheckboxListWithOverlay({ title, options, value, onChange }) {
  const [isOpen, setIsOpen] = React.useState(false)

  const hasUserSelection = Boolean(Object.keys(value).length)

  return (
    <>
      <button type='button' onClick={handleToggle} className={styles.checkboxButton}>
        {hasUserSelection ? 'Pas uw toelichting aan' : 'Geef uw toelichting'}
        <Icon icon={IconArrowRight} layoutClassName={styles.iconLayout} />
      </button>

      {isOpen && <Modal onModalClose={handleToggle} open={isOpen}>
        <div className={styles.modalInner}>
          <Button icon={IconCross} color='transparent' layoutClassName={styles.closeButtonLayout} onClick={handleToggle}>Sluit scherm</Button>
          <Heading32 {...{ title }} layoutClassName={styles.modalHeadingLayout} />
          <p className={styles.optionExplanationLayout}>
            Let op: U kunt meerdere opties selecteren
          </p>
          <CheckboxList {...{ options, value, onChange }} layoutClassName={styles.modalCheckboxListLayout} />
          <Button type='button' onClick={handleToggle}>Opslaan en verder</Button>
        </div>
      </Modal>}
    </>
  )

  function handleToggle() {
    setIsOpen(!isOpen)
  }
}

function CheckboxList({ options, value, onChange, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentCheckboxList, layoutClassName)}>
      {options.map((option, xid) => {
        return (
          <CheckboxWithLabel
            key={xid}
            label={option.label}
            name={option.value}
            value={option.value}
            checked={value[option.value] ?? false}
            onChange={handleChange}
          />
        )
      })}
    </div>
  )

  function handleChange(currentValue) {
    onChange(makeArrayValue({ inital: value, currentValue }))
  }
}

function useFormState({ id }) {
  const reportError = useReportError()
  const { user } = useUser()
  const database = useFirebaseDatabase()

  const { data, isFetching } = useFirebaseValue({
    reference: ({ user, database }) => query(
      ref(database, `/users/${user.uid}/statements/${id}/form`),
    ),
  })

  const initalFormState = {
    meaning: {}, frequency: {}, importance: 0,
    importanceWhy: {}, subjectToDiscussRating: 0, whyDiscuss: {}
  }

  return {
    isFetching,
    form: repairDataIfKeyIsNonExistingInCurrent(data),
    setValueOnKey
  }

  async function setValueOnKey({ value, key }) {
    try {
      await set(ref(database, `/users/${user.uid}/statements/${id}/form/${key}`), value)
    } catch (e) {
      reportError(e)
    }
  }

  // TODO This is not that nice
  function repairDataIfKeyIsNonExistingInCurrent(data) {
    return {
      ...initalFormState,
      ...data || {}
    }
  }
}

function makeArrayValue({ inital, currentValue }) {
  inital[currentValue] = inital[currentValue] ? null : true
  return inital
}
