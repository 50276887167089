import { StatementsDetailsForm } from './StatementsDetailsForm'
import { FirebaseContext } from '/domain/FirebaseContext'

export default function StatementsDetailsFormApp({ statement }) {
  return (
    <FirebaseContext>
      <StatementsDetailsForm {...{ statement }} />
    </FirebaseContext>
  )
}
