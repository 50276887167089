import { FloatingPortal, FloatingFocusManager, FloatingOverlay, useFloating, useRole, useDismiss, useInteractions } from '@floating-ui/react'
import { animated, config, useTransition } from '@react-spring/web'

import styles from './Modal.css'

export function Modal({ children, open, onModalClose }) {
  const { refs, context } = useFloating({
    onOpenChange: onModalClose,
    open
  })

  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'dialog' })

  const { getFloatingProps } = useInteractions([
    role, dismiss,
  ])

  const transitions = useTransition(open, {
    from: { opacity: 0, transform: [0.95, -2, 10] },
    enter: { opacity: 1, transform: [1, 0, 0] },
    leave: { opacity: 0, transform: [0.95, -2, 10] },
    config: config.stiff
  })

  return (
    <FloatingPortal>
      {transitions(({ opacity, transform }, portalOpen) =>
        portalOpen && (
          <FloatingOverlay
            // eslint-disable-next-line @kaliber/naming-policy
            lockScroll
            // eslint-disable-next-line @kaliber/layout-class-name
            className={cx(styles.overlay_root, (open && portalOpen) && styles.enabled)}
          >
            <FloatingFocusManager modal={false} {... { context }}>
              <animated.div
                ref={refs.setFloating}
                {...getFloatingProps()}
                className={styles.modalContainer}
                style={{
                  opacity,
                  transform: transform.to((scale, rotation, yAxis) => `
                    scale(${scale})
                    perspective(10em)
                    rotateX(${rotation}deg)
                    translateY(${yAxis}px)
                  `)
                }}
              >
                <div className={styles.modalContent}>
                  {children}
                </div>
              </animated.div>
            </FloatingFocusManager>
          </FloatingOverlay>
        ))}
    </FloatingPortal>
  )
}
