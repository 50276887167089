import { asRouteMap } from '@kaliber/routing'
import { cookieConfiguration } from '/machinery/tracking/cookieConfiguration'
import { statements } from './data/statements'

const routeData = getRouteData()

export const routeMap = asRouteMap(
  {
    root: '',
    api: {
      path: 'api',

      sitemap: {
        path: 'sitemap',
        data: requestHandlers => requestHandlers.sitemap,
      },

      v1: {
        path: 'v1',

        cookieConfiguration: {
          path: 'consents',
          data: requestHandlers => requestHandlers.setCookieConfiguration,
        },
      },

      notFound: '*'
    },
    app: {
      path: ':language',
      data: routeData.app.data,

      componentOverview: {
        path: 'component-overview'
      },

      home: {
        path: '',
      },

      aboutUs: {
        path: 'over-ons'
      },

      stepOne: {
        path: 'uw-leven',

        intro: {
          path: 'introductie',
          data: routeData.app.stepOneIntro
        },

        statements: {
          path: 'stellingen'
        },

        summary: {
          path: 'controleer'
        }
      },

      stepTwo: {
        path: 'toelichting',

        intro: {
          path: 'introductie',
          data: routeData.app.stepTwoIntro
        },

        overview: {
          path: 'overzicht'
        },

        statement: {
          path: ':id',
          data: routeData.app.statementDetailPage
        },
      },

      stepThree: {
        path: 'samenvatting',

        intro: {
          path: 'introductie',
          data: routeData.app.stepThreeIntro
        },

        select: {
          path: 'selecteer'
        },

        selected: {
          path: 'geselecteerd'
        },

        submit: {
          path: 'verstuur'
        }
      },

      cookiePolicy: {
        path: 'cookie-beleid',
        data: routeData.app.cookiePolicy,
      },

      notFound: '*',
    },
  },
  { trailingSlash: true }
)

function getRouteData() {
  return {
    app: {
      data: {
        fetch: {
          cookieConfiguration: requestHandlers => requestHandlers.getCookieConfiguration
        },
        derived: ({ data, params }) => ({
          title: 'Helder in gesprek',
          showCookieConsent: data?.cookieConfiguration?.configuration.show && data?.doc?._id !== 'cookiePolicy',
          dataLayer: {
            metadata: {
              ...cookieConfiguration(data?.cookieConfiguration?.configuration ?? null),
              content: {
                language: params.language,
              }
            }
          }
        }),
      },

      stepOneIntro: {
        derived: () => {
          return {
            title: 'Stap 1',
            subtitle: 'Uw dagelijks leven',
            paragraphs: ['Straks verschijnen een aantal stellingen. Door op ‘Ja’ of ‘Nee’ te klikken, geeft u aan of deze stelling wel of niet voor u van toepassing is.'],
            href: routeMap.app.stepOne.statements({ language: 'nl' }),
            label: 'Aan de slag'
          }
        }
      },

      stepTwoIntro: {
        derived: () => {
          return {
            title: 'Stap 2',
            subtitle: 'Toelichting',
            paragraphs: [
              'In de volgende stap kunt u de stellingen nader toelichten.',
              'Daarmee geeft u uw zorgverlener een nog completer beeld van uw situatie.'
            ],
            href: routeMap.app.stepTwo.overview({ language: 'nl' }),
            label: 'Start met Stap 2'
          }
        }
      },

      stepThreeIntro: {
        derived: () => {
          return {
            title: 'Stap 3',
            subtitle: 'Uw samenvatting',
            paragraphs: [
              'Bijna klaar! We zijn aangekomen bij de laatste stap.',
              'U heeft een aantal stellingen met ‘Ja’ beantwoord. Welke daarvan wilt u het liefst bespreken met uw zorgverlener? ',
              'In de volgende stap kunt u de 4 voor u belangrijkste stellingen selecteren.'
            ],
            href: routeMap.app.stepThree.select({ language: 'nl' }),
            label: 'Start met Stap 3'
          }
        }
      },

      statementDetailPage: {
        derived: ({ params }) => {
          return {
            currentStatement: statements[params.id] ?? null
          }
        }
      },

      cookiePolicy: {
        derived: () => ({
          title: 'Cookiebeleid'
        })
      }
    }
  }
}
