import { VisuallyHidden } from './VisuallyHidden'
import { Icon } from './Icon'
import { IconCheckmarkLarge } from '/icons'

import styles from './Checkbox.css'

export function Checkbox({ checked, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={cx(styles.checkbox, checked && styles.checkboxIsChecked)}>
        <Icon icon={IconCheckmarkLarge} />
      </div>
    </div>
  )
}

export function CheckboxWithLabel({ name, value, checked, label, onChange }) {
  return (
    <div className={styles.componentWithLabel}>
      <VisuallyHidden>
        <input
          id={name}
          type='checkbox'
          onChange={(event) => onChange(event.currentTarget.value)}
          {...{ checked, value }}
        />
      </VisuallyHidden>
      <label htmlFor={name} className={styles.checkboxLabel}>
        <Checkbox {...{ checked }} layoutClassName={styles.checkboxLayout} />
        {label}
      </label>
    </div>
  )
}
